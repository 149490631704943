import * as React from "react";
import Layout from "../components/layout";
import Block from "../components/block";
import styled from "styled-components";
import Footer from "../components/footer";
import { BREAKPOINTS } from "../consts";
import { useMediaQuery } from "react-responsive";
import { StaticImage } from "gatsby-plugin-image";
import { Gradient, GradientWapper } from "../components/gradients";
import coinList from "../../content/coins.json";
import { Link, graphql } from "gatsby";
import useDebounce from "../hooks/debounce";
import Decor from "../components/decor";

const Page = styled.div``;
const Header = styled.div`
  width: 35rem;
  padding: 6rem 0 0;
  text-align: center;
  p {
    margin: 0 0 1.4rem;
  }

  .mobile-only {
    display: none;
    visibility: hidden;
  }

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    width: 100%;
    padding: 3rem 0 0;

    .desktop-only {
      display: none;
      visibility: hidden;
    }

    .mobile-only {
      display: revert;
      visibility: visible;
    }
  }
`;
const ListContainer = styled.div`
  max-width: 40rem;
  width: 100%;
  padding: 3rem 0;
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    padding: 2rem 0;
  }
`;
const SearchContainer = styled.div`
  display: flex;
  width: 28rem;
  align-items: center;
  margin-bottom: 2rem;

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    width: 100%;
  }
`;
const SearchBox = styled.div`
  border: 2px solid rgb(255 255 255 / 40%);
  border-radius: 41px;
  padding: 0.7rem 1rem;
  display: flex;
  align-items: center;
  flex: 1;
  input {
    flex: 1;
    background: none;
    outline: none;
    border: 0;
    color: white;
    font-family: "DM SANS";
    font-size: 0.9rem;
    margin-left: 0.6rem;
  }
`;
const SearchCount = styled.div`
  margin-left: 1rem;
  opacity: 0.4;
  width: 5rem;

  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    display: none;
  }
`;
const AssetList = styled.ul`
  list-style: none;
  margin: 1rem 0;
  padding: 0;
`;
const AssetContainer = styled.div`
  background: rgb(49 49 58 / 30%);
  display: flex;
  padding: 0.8rem 1rem;
  margin-bottom: 0.6rem;
  border-radius: 12px;
  align-items: center;
  .icon {
    display: inline-flex;
    padding-right: 0.8rem;
    img {
      display: inline-block;
    }
  }
  .meta {
    .symbol {
      opacity: 0.4;
    }
  }
  @media screen and (max-width: ${BREAKPOINTS["mobile"]}px) {
    font-size: 0.9rem;
    padding: 0.7rem 0.9rem;
  }
`;

const assetsWithPages = {
  'Ethereum': 'ethereum',
  'Flare': 'flare',
  'Songbird': 'songbird',
  'XRP': 'xrp',
  'Polygon': 'polygon',
  'Optimism': 'optimism',
  'Fantom': 'fantom',
  'BNB': 'bnb',
  'XDC': 'xdc',
  'Arbitrum': 'arbitrum',
}

const Asset = ({ name, code, chains, iconImageData }) => {
  return (
    <AssetContainer>
      <div className="icon">
        <img alt={`${code} Icon`} srcSet={iconImageData.srcSet} />
      </div>
      <div className="meta">
        {
          name in assetsWithPages 
          ? <Link style={{textDecoration: 'underline'}} to={`/assets/${assetsWithPages[name]}/`}>{name}</Link>
          : name
        } <span className="symbol">({code})</span>
      </div>
    </AssetContainer>
  );
};

const SupportedAssetsPage = ({ data }) => {
  const [isMobile, setIsMobile] = React.useState(false);
  const mediaQuery = useMediaQuery({
    query: `(max-width: ${BREAKPOINTS["mobile"]}px)`,
  });
  React.useEffect(() => {
    setIsMobile(mediaQuery);
  }, [mediaQuery, isMobile]);

  const source = isMobile ? data.mobileCoins : data.desktopCoins;
  const coinIcons = Object.fromEntries(
    source.edges.map((k) => {
      return [k.node.name, k.node.childImageSharp.fixed];
    })
  );

  const DecorSettings = {
    desktop: [
      {
        colour: "white",
        size: 15,
        left: -260,
        top: -85,
      },
      {
        colour: "white",
        size: 11,
        left: 255,
        top: 116,
      },
      {
        colour: "purple",
        size: 22,
        left: 212,
        top: -100,
      },
      {
        image: data.ETHIcon,
        size: 44,
        left: 422,
        top: 52,
      },
      {
        image: data.XRPIcon,
        size: 44,
        left: -430,
        top: 90,
      },
      {
        image: data.DogeIcon,
        size: 44,
        left: 470,
        top: -100,
      },
      {
        image: data.FLRIcon,
        size: 44,
        left: -88,
        top: -134,
      },
      {
        image: data.FTMIcon,
        size: 44,
        left: -535,
        top: -100,
      },
    ],
    mobile: [
      {
        colour: "white",
        size: 8,
        left: -162,
        top: -60,
      },
      {
        colour: "white",
        size: 6,
        left: 124,
        top: 124,
      },
      {
        colour: "blue",
        size: 12.5,
        left: 110,
        top: -75,
      },
      {
        image: data.ETHIcon,
        size: 24,
        top: 22,
        left: 156,
      },
      {
        image: data.XRPIcon,
        size: 24,
        left: -166,
        top: 116,
      },
      {
        image: data.DogeIcon,
        size: 24,
        top: -74,
        left: 150,
      },
      {
        image: data.FLRIcon,
        size: 24,
        left: -40,
        top: -86,
      },
      {
        image: data.FTMIcon,
        size: 24,
        left: -180,
        top: -30,
      },
    ],
  };

  const [searchTerm, setSearchTerm] = React.useState("");
  const [results, setResults] = React.useState(coinList);
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  React.useEffect(() => {
    let term = debouncedSearchTerm.toLowerCase();
    if (term) {
      setResults(
        coinList.filter((coin) => {
          const symbolMatch = coin.symbol.toLowerCase().includes(term);
          const nameMatch = coin.name.toLowerCase().includes(term);
          return nameMatch || symbolMatch;
        })
      );
    } else {
      setResults(coinList);
    }
  }, [debouncedSearchTerm]);

  return (
    <Page>
      <Layout title={"Supported Assets"}>
        <GradientWapper>
          <Block>
            <Gradient position="topLeft" color="purple" />
            <Header>
              <Decor
                settings={
                  isMobile ? DecorSettings.mobile : DecorSettings.desktop
                }
              >
                <h1 className="mobile-only">
                  See the full list of supported assets
                </h1>
                <h1 className="desktop-only">Supported crypto assets</h1>
              </Decor>
              <p className="mobile-only">
                Search your favourite assets or find out more by clicking the
                link below.
              </p>
              <p className="desktop-only">
                Search your favourite assets or find out more by clicking the
                link below.
              </p>
              <p></p>
              <a
                href="https://support.bifrostwallet.com/en/articles/5578841-supported-crypto-assets"
                target="_blank"
                rel="noreferrer"
                className="button"
              >
                <span className="mobile-only">Crypto assets</span>
                <span className="desktop-only">More about supported crypto assets</span>
                <StaticImage
                  src="../images/LinkIcon.png"
                  alt="Arrow"
                  className="external-arrow"
                  height={18}
                  quality={100}
                  placeholder="none"
                />
                <div className="overlay"></div>
              </a>
            </Header>
          </Block>
        </GradientWapper>

        <Block noPadding={true}>
          <ListContainer>
            <SearchContainer>
              <SearchBox>
                {isMobile ? (
                  <StaticImage
                    src="../images/Search.png"
                    quality={100}
                    alt="Search"
                    placeholder="none"
                    height={20}
                  />
                ) : (
                  <StaticImage
                    src="../images/Search.png"
                    quality={100}
                    alt="Search"
                    placeholder="none"
                    height={28}
                  />
                )}
                <input
                  type="text"
                  id="supported-search"
                  placeholder="Search by name or symbol..."
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </SearchBox>
              <SearchCount>
                (
                {debouncedSearchTerm
                  ? `${results.length}/${coinList.length}`
                  : coinList.length}
                )
              </SearchCount>
            </SearchContainer>

            <AssetList>
              {results.map((data, index) => {
                return (
                  <li key={index}>
                    <Asset
                      name={data.name}
                      code={data.symbol}
                      iconImageData={coinIcons[data.symbol]}
                    ></Asset>
                  </li>
                );
              })}
            </AssetList>
          </ListContainer>
        </Block>
      </Layout>
      <Footer />
    </Page>
  );
};

export const query = graphql`
  query SupportedAssetsQuery {
    ETHIcon: file(name: { eq: "ETH" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    FTMIcon: file(name: { eq: "FTM" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    FLRIcon: file(name: { eq: "FLR" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    DogeIcon: file(name: { eq: "Doge" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    XRPIcon: file(name: { eq: "XRP" }) {
      childImageSharp {
        gatsbyImageData(placeholder: NONE)
      }
    }
    desktopCoins: allFile(filter: { sourceInstanceName: { eq: "coins" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 30, height: 30) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    mobileCoins: allFile(filter: { sourceInstanceName: { eq: "coins" } }) {
      edges {
        node {
          name
          childImageSharp {
            fixed(width: 24, height: 24) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;

export default SupportedAssetsPage;
